/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import useUrlParam from '../../utils/useUrlParam'
import { trackEvent } from '../../utils/eventTrack'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

const Wrapper = styled.div`
  height: 100vh;
`

const ADASCScan = () => {
  useEffect(() => {
    const url = useUrlParam(window.location.search, 'url')
    const utmSource = useUrlParam(window.location.search, 'utm_source')
    const utmMedium = useUrlParam(window.location.search, 'utm_medium')
    const utmCampaign = useUrlParam(window.location.search, 'utm_campaign')
    const partner = useUrlParam(window.location.search, 'partner')

    if (url && utmSource && utmMedium && utmCampaign && partner) {
      trackEvent(`Site Scan URL Submitted`, {
        'Site URL': url,
        'ADASC Scan': true,
      })
      navigate(
        encodeURI(
          `/scan/results/?url=${url}&partner=${partner}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
        )
      )
    } else {
      navigate(encodeURI(`/`))
    }
  }, [])

  return (
    <Layout customSEO hideHeaderFooterNav>
      <SEO
        title="AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        noIndex
        noFollow
      />
      <Wrapper />
    </Layout>
  )
}

export default ADASCScan
